import React from 'react';
import './intro.css';

const Intro = () => (
    <section className="intro">
        <h1 className="intro__heading">SW Software Solutions</h1>
        <p className="intro__description">Contract software development services<br />based in South Manchester.</p>
    </section>
);

export default Intro;
